.nav-centaur {
  @extend %animate--z;
  @include absolute(r .75);
  @include z-index(ground);
  opacity: 0;

  @include mediaquery(m) {
    @include absolute(r 0);
  }

  @include mediaquery(l) {
    @include absolute(r -3);
  }

  &--toggle:checked ~ & {
    @include z-index(belly);
    opacity: 1;
  }

  ul {
    @include spacing-inner(a .5);
    list-style: none;
    border: $width-icon-border $color-main solid;
    max-width: 100%;
    width: $base-quadruple * 3;
    overflow: hidden;
    background: $color-background;

    @include mediaquery(m) {
      border-width: $width-icon-border * 2;
    }
  }

  li {
    @include spacing-inner(v .2);
    text-align: center;

    &.active {
      a {
        @extend %item-active;
      }
    }
  }

  a {
    @include font-size(h4);
    @include letter-spacing(l);
    color: $color-default;
  }

  &--wrapper {
    @include relative;
    @include spacing-inner(b 2);
    text-align: center;

    @include mediaquery(m) {
      text-align: right;
    }

    @include mediaquery(l) {
      @include absolute(t 3, r 8);
    }

    .icon {
      @extend %animate;
    }

    label {
      cursor: pointer;
    }

    input {
      &:checked + label .icon {
        transform: rotate(180deg);
      }
    }
  }

  &--toggle {
    display: none;
  }
}
