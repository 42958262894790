// global header styles
// ======================================================================
.navigation--toggle {
  @include fixed;
  @include z-index(head);
  width: 100%;

  .toggle {
    @extend %animate;

    @include mediaquery(m) {
      transform: translateX(0%);

      &--active {
        transform: translateX(85%);
      }
    }

    &:before {
      @include mediaquery(m) {
        @include absolute(t .5, b .5, l 1.55);
        @include z-index(head);
        content: '';
        background: $color-main;
        width: 2px;
        height: 99vh;
      }
    }
  }
}

.button {
  &--nav {
    @extend %button--blank;
    @include absolute(t .6, r .45);
    @include z-index(head);
    width: 30px;
    height: 21px;

    @include mediaquery(m) {
      top: $base-half;
      right: $base-half * .7;
    }

    @include mediaquery(l) {
      top: $base-half;
      right: $base-half * .65;
    }
  }

  &--nav__inner {
    @extend %button--nav;
    @extend %animate;
    content: normal;
    transform: none;
    background: $color-main;
    border-radius: $width-menu;

    &::before {
      @extend %button--nav;
      @include absolute(b 0, l 50%);
      background: $color-main;
      border-radius: $width-menu;
    }

    &::after {
      @extend %button--nav;
      @include absolute(t 0, l 50%);
      background: $color-main;
      border-radius: $width-menu;
    }
  }

  &--nav--active {
    .button--nav__inner {
      background: rgba($color-inverted, .01);

      &::before {
        transform: translateX(-35%) translateY(100%) rotate(-45deg);
        transform-origin: top left;
      }

      &::after {
        transform: translateX(-30%) rotate(45deg);
        transform-origin: top left;
      }
    }
  }
}
