// default link styling
// ======================================================================

a {
  color: $color-main;
  text-decoration: none;

  @include attention {
    text-decoration: none;
  }
}

.link {
  &--back {
    color: $color-default;

    .icon {
      transform: rotate(90deg);
    }
  }
}
