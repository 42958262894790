// content styles
// ======================================================================

main {
  @include spacing-inner(a .75, t 5);
  @include relative;
  @include z-index(belly);
  min-height: 93vh;

  @include mediaquery(m) {
    @include spacing-inner(v 1.5, h 4);
  }
}

.wrapper--firstimg {
  @include mediaquery(m) {
    img:nth-of-type(1) {
      @include spacing(b 0);
      @include spacing-inner(b 3, l 0);
      max-width: 60vw;

      @include mediaquery(xl) {
        @include spacing(l 2.5);
      }

      + blockquote {
        @include absolute(t 5, r 2.5);
        @include spacing-inner(l 50vw);

        @include mediaquery(l) {
          @include spacing-inner(l 40vw);
        }

        @include mediaquery(xl) {
          @include spacing-inner(l 25vw);
          top: 6 * $base-unit;
        }

        @include mediaquery(xl) {
          @include spacing-inner(l 8);
          top: 8 * $base-unit;
        }
      }
    }
  }
}

.page--extra-overview {
  blockquote {
    color: $color-second;
  }

  .extra {
    @include mediaquery(xl) {
      @include spacing(l 2.5);
    }
  }
}

.page--vita main {
  @include mediaquery(m) {
    img {
      @include spacing(b 0);
      @include spacing-inner(b 1);
      max-width: 40vw;

      &:nth-of-type(odd) {
        @include spacing-inner(l 1);
        float: right;
      }

      &:nth-of-type(even) {
        @include spacing-inner(r 1);
        float: left;
      }

      &:nth-of-type(1) {
        float: none;
      }
    }
  }

  hr {
    clear: both;
  }
}
