// default headings h1 - h6
// ======================================================================

h1 {
  @include font-size(h1);
  @include center(horizontal);
  @include spacing-inner(h .5, v .25);
  @include letter-spacing(l);
  text-transform: uppercase;
  color: $color-second;
  border: 2px solid $color-main;
  display: inline-block;
  font-weight: 600;
  font-family: $font-family-headings;
  text-align: center;

  main & {
    @include mediaquery(m) {
      font-weight: 700;
      left: 0;
      transform: translateX(0);
    }
  }
}

h2 {
  @include font-size(h2);
  @include spacing-inner(h .25);
  @include letter-spacing(s);
  text-transform: uppercase;
  color: $color-second;
  background: $color-main;
  display: inline;
  font-weight: 600;
  font-family: $font-family-headings;
  box-decoration-break: clone;

  @include mediaquery(m) {
    font-weight: 700;
  }

  + h3,
  + p {
    @include spacing(t 1.5);
  }
}

h3 {
  @include font-size(h3);
  font-family: $font-family-headings;
}

h4 {
  @include font-size(h4);
}

h5 {
  @include font-size(h5);
}

h6 {
  @include font-size(h6);
}
