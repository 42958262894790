// special link styles
// ======================================================================

.link--back {
  @include absolute(l .75, t 2);
  @include z-index(head);

  @include mediaquery(m) {
    @include absolute(l auto, t 1.5);
  }

  @include mediaquery(l) {
    @include absolute(t 3);
  }
}
