.footnotes {
  border-top: $width-icon-border solid $color-main;
  border-right: $width-icon-border solid $color-main;
  border-left: $width-icon-border solid $color-main;

  &-header {
    @include spacing-inner(v .5);
    @include spacing(b 0);
    border-bottom: $width-icon-border solid $color-main;
    color: $color-second;
    font-family: $font-family;
    text-align: center;

    @include mediaquery(l) {
      @include spacing-inner(l 2);
      text-align: left;
    }

    .icon {
      transform: rotate(180deg);

      .footnotes--up & {
        transform: rotate(0deg);
      }
    }
  }

  &-list {
    @include spacing(b 0);
    @include spacing-inner(a 1);
    list-style: none;
    counter-reset: li;
    border-bottom: $width-icon-border solid $color-main;

    &.footnotes-list--up {
      transition: height 2s ease-out;
      height: 0;
      overflow: hidden;
      padding: 0;
      border: 0;
    }
  }

  li {
    @include spacing-inner(b 1);
    @include relative;
    counter-increment: step;
    overflow: hidden;

    @include mediaquery(l) {
      @include spacing-inner(l 1);
    }

    &:before {
      content: counter(step, decimal-leading-zero) ' ';

      @include mediaquery(l) {
        @include absolute;
      }
    }
  }

  p {
    display: inline;
  }
}
