// welcome splash screen
// ======================================================================

.welcome {
  @extend %animate;
  @include fixed(a 0);
  @include z-index(hair);
  overflow: scroll;
  background: $color-background;

  .close-state--welcome:checked ~ & {
    transform: translateX(100%);
  }

  &--done {
    transition: unset;
  }

  .nav-language {
    @include spacing(t .75);

    @include mediaquery(m) {
      @include spacing(t .75, b 0, h auto);
      @include spacing-inner(r $width-icon * 2);
      max-width: map-get($breakpoints, xl);
      display: block;
      text-align: right;
    }
  }

  .page--no-welcome & {
    display: none;
    transition: unset;
  }

  header {
    max-width: map-get($breakpoints, m);
    margin: 0 auto;
  }

  main {
    @include spacing-inner(t 1.5);
    min-height: 5vh;
    max-width: map-get($breakpoints, xl);
    margin: 0 auto;
  }

  h1 {
    @include font-size(default);
    color: $color-default;
    border: 0;
    text-transform: none;
    font-family: $font-family;
    font-weight: 300;
  }

  footer {
    background: $color-background;
    max-width: map-get($breakpoints, xl);
    margin: 0 auto;

    a {
      color: $color-default;
    }
  }

  cite {
    display: none;

    .no-js &,
    &.cite--show {
      display: block;
    }
  }
}
