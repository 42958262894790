// global font sizes scss-map variable - use with mixin
// ======================================================================

$font-sizes: (
  h1: 26px,
  h2: 26px,
  h3: 22px,
  h4: 18px,
  h5: 16px,
  h6: 15px,
  default: $font-size,
  small: 14px
);

$letter-spacings: (
  s: .05em,
  m: .1em,
  l: .15em,
  xl: .2em
);
