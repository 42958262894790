.page--timeline-overview main,
.page--timeline main {
  overflow: hidden;

  p {
    @include spacing(b 1.5, t 1);
  }
}

.timeline {
  &--above {
    @include relative;
    height: $base-quadruple * 3.8;

    .timeline--header {
      @include spacing-inner(t 13.1);
    }
  }

  &--below {
    @include spacing-inner(a 1, t 0);
    @include relative;
    height: $base-quadruple * 3.8;
  }


  &--above__content,
  &--below__content {
    @extend %animate;
    @include absolute;
    width: 100%;
    height: $base-quadruple * 3;
    overflow-y: scroll;
    background: $color-inverted;

    &--prev {
      transform: translateX(-200%);
      opacity: 0;
    }

    &--next {
      transform: translateX(200%);
      opacity: 0;
    }
  }

  &--above__content {
    @include spacing-inner(a 1);
    border: $width-icon-border * 2 solid $color-main;
  }

  &--below__content {
    @include spacing(t 3);
  }

  &--middle {
    @include spacing-inner(a .5);
    @include relative;
    @include spacing(h -.75);
    background: $color-second-light;

    @include mediaquery(m) {
      @include spacing(h 0);
    }

    &:after,
    &:before {
      content: '';
      width: $width-icon-triangle;
      height: $width-icon-triangle;
      background: $color-second-light;
      transform: translateX(-50%) rotate(45deg);
    }

    &:before {
      @include absolute(l 50%, t (- $width-icon-triangle / 2));
    }

    &:after {
      @include absolute(l 50%, b (- $width-icon-triangle / 2));
    }

    .icon--arrow {
      fill: $color-second;
    }
  }

  &--nav {
    @include spacing(h 1);
    @include relative;
    overflow: hidden;
    height: $base-unit;

    &__link--prev {
      @include absolute(t .5, l .5);

      .icon {
        transform: rotate(90deg);
      }

      &:before {
        @include z-index(belly);
        @include absolute(l 1, t -.5);
        content: '';
        width: $base-quadruple;
        height: $base-double;
        background: linear-gradient(to right, $color-second-light, rgba($color-second-light, 0));
      }
    }

    &__link--next {
      @include absolute(t .5, r .5);

      .icon {
        transform: rotate(-90deg);
      }

      &:before {
        @include z-index(belly);
        @include absolute(r 1, t -.5);
        content: '';
        width: $base-quadruple;
        height: $base-double;
        background: linear-gradient(to left, $color-second-light, rgba($color-second-light, 0));
      }
    }

    ul {
      @extend %animate;
      @include absolute();
      list-style: none;
      height: $base-unit;
      overflow: hidden;
      width: 3000px;
      transform: translateX(25vw);
    }

    li {
      display: inline-block;

      &:before,
      &:after {
        content: '\2022\2022\2022';
        color: $color-second;
      }

      &:before {
        @include spacing-inner(r .5);
      }

      &:after {
        @include spacing-inner(l .5);
      }
    }

    a,
    strong {
      color: $color-second;
      font-weight: 600;
      font-family: $font-family-headings;
      display: inline-block;
      width: $base-unit * 1.5;
      text-align: center;
    }
  }

  &--header {
    @include letter-spacing(s);
    @include spacing(v 1);
    text-align: center;
    font-family: $font-family;
    font-weight: 300;
    text-transform: uppercase;
  }

}
