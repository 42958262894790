// basic element styles for video / audio etc.
// ======================================================================

audio,
canvas,
img,
video {
  @include max(width);
}

audio,
canvas,
iframe,
img,
svg,
video {
  vertical-align: middle;
}

.media {
  @include mediaquery(m) {
    &--60 {
      width: 60vw;
      max-width: 600px;
    }

    &--50 {
      width: 50vw;
      max-width: 500px;
    }

    &--right {
      @include absolute(t 0, r 0);
      @include z-index(ground);
    }
  }
}
