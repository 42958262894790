// buttons
// ======================================================================

// on the how and why of @extend, read:
// http://csswizardry.com/2014/11/when-to-use-extend-when-to-use-a-mixin/

// default button styles for every button
%button {
  display: inline-block;
  cursor: pointer;
}

%button--nav {
  content: '';
  display: block;
  width: 30px;
  height: $width-menu;
  background: $color-main;
  backface-visibility: hidden;
  transform: translateX(-50%);
  transition: all .4s;
}

%button--blank {
  outline: 0;
  border: 0;
  padding: 0;
  margin: 0;
  line-height: 1;
  background-color: transparent;
  cursor: pointer;
  display: inline-block;
}

.button {
  @extend %button;
  @include relative;
  @include z-index(feet);
  @include spacing-inner(v .5, h 1);
  @include letter-spacing(l);
  font-weight: 600;
  border: 2px solid $color-border;
  transition: border-color 0.3s, color 0.3s;
  transition-timing-function: cubic-bezier(0.2, 1, 0.3, 1);
  overflow: hidden;
  display: block;
  text-align: center;
  color: $color-second;

  @include attention {
    text-decoration: none;
    border: 2px solid $color-main;

    &::before {
      transform: rotate3d(0, 0, 1, 0deg);
      transition-timing-function: cubic-bezier(0.2, 1, 0.3, 1);
      opacity: 1;
    }
  }

  &:before {
    @include absolute;
    @include z-index(ground);
    content: '';
    width: 150%;
    height: 100%;
    background: $color-main;
    transform: rotate3d(0, 0, 1, -45deg) translate3d(0, -3em, 0);
    transform-origin: 0% 100%;
    transition: transform 0.3s, opacity 0.3s, background-color 0.3s;
  }

  &--with-icon {
    @include relative;
    @include center;
    width: $base-quadruple * 3;

    .wrapper--center & {
      @include mediaquery(m) {
        @include spacing(a 0);
      }
    }
  }

  &--close {
    @include z-index(sky);
    cursor: pointer;

    &--welcome {
      @include relative;
      @include spacing(t .75, b 0, h auto);
      @include spacing-inner(r .75);
      max-width: map-get($breakpoints, xl);
      display: block;
      text-align: right;

      .close-state--welcome:checked ~ & {
        display: none;
      }
    }
  }
}

.close-state {
  @extend %visuallyhidden;
}
