// svg icons and custom icon styles
// ======================================================================

.icon {
  @include sprite(1em, 1em, currentColor);
  position: relative;
  top: -0.0625em;
  display: inline-block;
  vertical-align: middle;

  &--paperclip {
    @include absolute(t -1.3, l .75);
    @include sprite(18px, 50px, $color-second);
    @include z-index(belly);

    @include mediaquery(m) {
      @include absolute(t -1.55);
      @include sprite(27px, 72px);
    }

    @include mediaquery(l) {
      @include absolute(t -1.4);
    }
  }


  &--twitter,
  &--instagram,
  &--contact {
    $dimensions: $width-icon - $width-icon-border * 2;
    @include sprite($dimensions, $dimensions, $color-second);
    @include spacing-inner(a .45);

    @include mediaquery(m) {
      @include spacing-inner(a .3);
    }
  }

  &--arrow-right--round {
    @include sprite($width-icon-close, $width-icon-close);
    @include fixed(t .9, r .75);

    @include mediaquery(xl) {
      right: auto;
    }
  }

  &--checkbox {
    fill: $color-main;
  }

  &--checkbox--checked {
    display: none;
    fill: $color-second;
  }

  &--arrow {
    .footnotes & {
      @include spacing(l -1, r .25);
      color: $color-main;
    }

    .blog__gallery-nav--next & {
      @include sprite($height-gallery-arrow, $height-gallery-arrow);
      transform: rotate(-90deg);
    }

    .blog__gallery-nav--prev & {
      @include sprite($height-gallery-arrow, $height-gallery-arrow);
      transform: rotate(90deg);
    }
  }
}
