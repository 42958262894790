// a mixin for positioning svg icons
// ======================================================================

// usage examples:
//
// @include sprite; ==> width: 1em; height: 1em;
// @include sprite(18px, 50px, $color-second); => width: 18px; height: 50px; fill: $color-second;
// @include sprite(1, 2, none, $color-second, 2px); width: $base-unit; height: $base-double; stroke: $color-second; stroke-width: 2px;
@mixin sprite($width: 1em, $height: 1em, $fill: none, $stroke: none, $stroke-width: none) {
  width: parse-unit($width);
  height: parse-unit($height);

  @if $fill != none {
    fill: $fill;
  }

  @if $stroke != none {
    stroke: $stroke;
    stroke-width: parse-unit($stroke-width);
  }
}
