.page--centaur {
  code {
    @include letter-spacing(xl);
    font-family: $font-family;
  }
}

.page--centaur-overview {
  main {
    @include mediaquery(l) {
      @include spacing-inner(l 5);

      .nav-language + p {
        @include spacing(l -1.5);
      }
    }
  }
}

.centaur {
  @include relative;
  @include spacing-inner(t 2);

  @include mediaquery(l) {
    @include spacing(b 1.5);
    @include spacing-inner(t 0);
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
  }

  &__foreign,
  &__current {
    header {
      @include absolute(t 0);
      @include spacing-inner(h 1);
      display: none;
      width: 50%;

      @include mediaquery(l) {
        @include relative;
        width: 100%;
        display: block;
        text-align: center;
      }

      .centaur--switch & {
        display: block;
      }
    }

    h3 {
      @include spacing-inner(h .2);
      display: inline;
      font-weight: 300;
      font-family: $font-family;
      cursor: pointer;

      @include mediaquery(l) {
        color: $color-second;
        display: block;
        cursor: default;
      }
    }

    ul {
      display: none;

      @include mediaquery(l) {
        display: block;
      }
    }
  }

  &__current {
    header {
      text-align: right;

      @include mediaquery(l) {
        text-align: center;
      }
    }
  }

  &__foreign {
    header {
      right: 0;
    }
  }

  &--active {
    h3 {
      @extend %item-active;

      @include mediaquery(l) {
        background: transparent;
      }
    }

    ul {
      display: block;
    }
  }

  ul {
    list-style: none;
  }

  li {
    @include spacing(b .5);
  }

  a {
    @include font-size(h4);
    text-transform: uppercase;
    font-family: $font-family-headings;
    font-weight: 700;

    @include mediaquery(l) {
      @include spacing-inner(h .75);
      @include font-size(small);
    }

    @include mediaquery(xl) {
      @include spacing-inner(h 1.5);
      @include font-size(h6);
    }

    @include mediaquery(xxl) {
      @include spacing-inner(h 3);
    }
  }
}
