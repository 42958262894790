// breakpoints used throughout the project - use with mixin
// ======================================================================

$breakpoints: (
  s: 480px,
  m: 720px,
  l: 960px,
  xl: 1100px,
  xxl: 1300px,
  xxxl: 1600px
);

