// basic lists styling and reset
// ======================================================================

nav,
fieldset {
  ul,
  ol {
    list-style: none;
  }
}

.social__list {
  @include spacing-inner(l 0);
  list-style: none;
  text-align: center;

  li {
    @include spacing(b .5);
    @include spacing-inner(h .25);
    display: inline-block;
  }

  a {
    @extend %animate;
    border-radius: $width-icon / 2;
    border: $width-icon-border solid $color-second;
    display: block;
    width: $width-icon;
    height: $width-icon;

    @include attention {
      background: $color-main;
      transform: scale(1.1);
    }
  }
}
