// text (inline) elements and others
// ======================================================================

hr {
  @include center;
  display: block;
  height: 2px;
  width: $base-quadruple;
  padding: 0;
  border: 0;
  background: $color-main;

  @include mediaquery(m) {
    @include spacing(l 30%);
  }
}

blockquote {
  @include spacing-inner(l 15%);
  color: $color-second-light;
  font-weight: 600;
  font-family: $font-family-headings;
  max-width: $base-quadruple * 6;

  @include mediaquery(m) {
    @include spacing-inner(l 14vw);
  }

  @include mediaquery(xl) {
    @include spacing-inner(l 7vw);
  }

  .wrapper--inverse__text & {
    @include mediaquery(m) {
      @include spacing(l 4vw, b 3);
      @include spacing-inner(t 4, l 0);
    }

    @include mediaquery(xl) {
      @include spacing(l 9vw);
    }

    @include mediaquery(xxl) {
      @include spacing(l 14vw);
    }
  }
}

mark {
  @include spacing-inner(h .25);
  @include letter-spacing(s);
  background: $color-main;
  color: $color-second;
  font-weight: 600;
  font-family: $font-family-headings;
  text-transform: uppercase;
  line-height: 1.75;
  box-decoration-break: clone;
}

.wrapper--inverse__text p {
  @include mediaquery(m) {
    @include spacing(r 2vw);
  }

  @include mediaquery(l) {
    @include spacing(r 5vw);
  }

  @include mediaquery(xl) {
    @include spacing(r 10vw);
  }
}

.text {
  &--spaced {
    @include letter-spacing(m);
    text-align: center;
  }
}

cite {
  @include letter-spacing(s);
  text-transform: uppercase;
  display: block;
  font-style: normal;
  font-weight: 600;
  color: $color-second;
  font-family: $font-family-headings;
  text-align: center;
}

.no-scroll {
  overflow: hidden;
}
