// base styles for html, body and other global elements
// ======================================================================

html {
  font-size: $font-size-mobile;
  min-height: 100%;
  overflow-y: scroll; // all without overlaying scrollbars
  font-family: $font-family;
  font-weight: $font-weight;
  line-height: $font-line-height;
  color: $color-default;
  background-color: $color-background;
  text-size-adjust: 100%; // ios 8+

  @include mediaquery(m) {
    font-size: decimal-round($font-size-mobile + ($font-size - $font-size-mobile) / 2);
  }

  @include mediaquery(l) {
    font-size: $font-size;
  }
}

body {
  background: $color-background;

  @include mediaquery(print) {
    * {
      display: none;
    }

    &::after {
      content: 'Please, do not waste paper by printing webpages.';
    }
  }
}

// text selection styles
::selection {
  background: $color-default;
  color: $color-main;
  text-shadow: none;
}
