// wrapper styles
// ======================================================================
%centered {
  max-width: 1440px;
  margin: 0 auto;
}

.wrapper {
  @include relative;

  &__text {
    @extend %rhythm;

    @include mediaquery(m) {
      @include absolute(t 4, l 28vw);
    }

    @include mediaquery(xl) {
      left: $base-unit * 11;
    }
  }

  &--inverse__text {
    @extend %rhythm;

    @include mediaquery(m) {
      @include spacing(r 45vw);
      min-height: 650px;
    }

    @include mediaquery(xl) {
      @include spacing(r 37vw);
    }

    @include mediaquery(xxl) {
      @include spacing(r 30vw);
    }

    @include mediaquery(xxxl) {
      @include spacing(r 14);
    }
  }

  &--center {
    @extend %centered;
    @include relative;
  }

  &--button--nav {
    @include absolute(r 1.5, t 1.2);
    @include z-index(sky);
    height: $width-icon;
    width: $width-icon;
    border-radius: $width-icon / 2;
    background: $color-second;

    @include mediaquery(m) {
      right: auto;
      top: 35vh;
      left: $base-half * 1.5;
    }

    &--wide {
      right: auto;
      left: 80vw;
    }
  }

  &--min {
    min-height: $base-quadruple * 7;
  }

  &--centaur--wide {
    min-width: $width-centaur-wide + 2;
  }

  &--hint {
    @extend %centered;
    @include relative;
    text-align: center;

    strong {
      color: $color-second-light;
    }

    p {
      @include spacing(b 1);
    }

    h1 {
      @include spacing(b 4);
      left: auto;
      transform: none;
    }

    .button--with-icon {
      @include spacing(t 5);
    }
  }
}
