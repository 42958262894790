// navigation styles
// ======================================================================
%item-active {
  @include spacing-inner(h .2);
  color: $color-second;
  background: $color-main;
}

.nav-main,
.nav-language {
  a {
    @include relative;
    @include letter-spacing(m);
    color: $color-default;
    display: inline-block;

    @include attention {
      color: $color-second;

      &:before {
        width: 100%;
      }
    }

    &.nav-main__link--active {
      @extend %item-active;
    }

    &:before {
      @include absolute(t 0);
      @include z-index(ground);
      display: block;
      content: '';
      height: decimal-floor($font-size-mobile * $font-line-height);
      line-height: decimal-floor(22.5);
      width: 0%;
      background: $color-main;
      transition: width 0.5s ease;
    }
  }
}

.nav-main {
  ul {
    text-align: center;
  }

  li {
    @include spacing-inner(v .5);

    &.active a {
      background: $color-main;
    }

    &:last-child {
      @include spacing-inner(t 1);
    }
  }

  a {
    text-transform: uppercase;

    &:before {
      @include mediaquery(m) {
        height: $font-size * $font-line-height;
      }
    }
  }
}

.nav-language {
  @include spacing-inner(r $width-icon * 2);
  text-align: right;

  @include mediaquery(m) {
    @include spacing-inner(r 0);

    .header & {
      text-align: center;
    }
  }

  main & {
    display: none;

    @include mediaquery(m) {
      display: block;
    }
  }

  li {
    @include spacing-inner(l .5);
    display: inline-block;
  }

  span {
    @extend %item-active;
    @include letter-spacing(m);
    font-size: $font-size-mobile;
    display: inline-block;
  }

  a {
    font-size: $font-size-mobile;
    color: $color-default;
  }
}

.navigation {
  @extend %animate;
  @extend %centered;
  @include z-index(head);
  @include spacing-inner(a .75, t 2);
  @include fixed(a 0);
  display: none;
  border-bottom: 2px solid $color-main;
  background: rgba($color-inverted, .95);
  overflow: scroll;

  @include mediaquery(m) {
    transform: translateX(-200%);
    display: block;
  }

  &--active {
    display: block;

    @include mediaquery(m) {
      transform: translateX(0%);
    }
  }
}
