// @font-face mixin using woff + ttf files
// ======================================================================

// custom webfonts mixin. IE8 is no longer supported so we can throw
// eot over board, and just use woff and ttf files. we assume
// that the fonts-directory is relative to the compiled css directory

@mixin font-face($family, $file, $weight: normal, $style: normal) {
  @font-face {
    font-family: $family;
    font-weight: $weight;
    font-style: $style;
    src: url('#{$file}.woff2') format('woff2'), url('#{$file}.woff') format('woff');
  }
}


// mixin for getting the correct font-size
// ======================================================================

@mixin font-size($fontsize) {
  font-size: px-to-rem(map-get($font-sizes, $fontsize));
}

// mixin for getting the correct letter-spacing
// ======================================================================

@mixin letter-spacing($size) {
  letter-spacing: map-get($letter-spacings, $size);
}
