// some basic keyframe animations
// ======================================================================

// rotates an element once
@keyframes rotation {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

// it is wise to try to animate only transforms and opacity for animation
// because those are cheap for the browser and result in smoother animations

// default animation, 400ms ^= jquery default animation time :-)
.animate,
%animate {
  transition: opacity 0.4s ease-in, transform 0.4s ease-in;
}

.animate--z,
%animate--z {
  transition: z-index 0.4s ease-in, opacity 0.4s ease-in;
}
