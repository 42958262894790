// color variables used throughout the project
// ======================================================================

// non-semantic color-names to make your life easier. assign
// them, but only use them to reassign them below. NEVER use
// them directly in other scss-files
$color-black: #000;
$color-white: #fff;
$color-red: #f00;
$color-grey: #535353;
$color-blue-light: #7fb4f1;
$color-blue-dark: #221f8b;
$color-orange: #ffd6a2;

// colors you use in your project and other scss-files
$color-default: $color-grey;
$color-main: $color-orange;
$color-second: $color-blue-dark;
$color-second-light: $color-blue-light;
$color-inverted: $color-white;
$color-background: $color-white;
$color-border: $color-main;
$color-error: $color-red;
