// base unit definition and default font styles and family
// ======================================================================

// base font settings and font-size
$font-size: 20px; // default font-size -- will be used as 1rem
$font-size-mobile: 15px; // default font-size -- will be used as 1rem
$font-weight: normal;
$font-line-height: 1.5;
$font-family: WorkSans, Arial, sans-serif;
$font-family-headings: Laila, Georgia, serif;

// base unit - default font-size * line-height in rem and half/double
$base-unit-in-px: $font-size * $font-line-height;
$base-unit: 1rem * $font-line-height;
$base-half: $base-unit / 2;
$base-third: $base-unit / 3;
$base-quart: $base-unit / 4;
$base-double: $base-unit * 2;
$base-triple: $base-unit * 3;
$base-quadruple: $base-unit * 4;

// globally used widths (max-container sizes e.g.)
$width-sth: 1000px;
$width-menu: 3px;
$width-icon: 50px;
$width-icon-border: 2px;
$width-icon-close: $width-icon / 2;
$width-centaur-wide: 42rem;
$width-icon-triangle: 16px;

// globally used heights (header-height e.g.)
$height-gallery-img: 200px;
$height-gallery-arrow: .6;

