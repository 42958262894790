// box
// ======================================================================

.box {
  overflow: hidden;

  &--filled {
    @include spacing-inner(a 2, b 4);
    background: $color-second-light;
    color: $color-inverted;

    @include mediaquery(l) {
      width: 65vw;
    }

    @include mediaquery(xl) {
      width: 60vw;
    }

    @include mediaquery(xxl) {
      width: 60vw;
    }

    @include mediaquery(xxxl) {
      width: $base-unit * 28;
    }
  }

  &--bordered {
    @include spacing(l 14vw, t -2, r -.75, b 3);
    @include spacing-inner(l 2, t 3, r 1);
    border: $color-main $width-icon-border solid;

    @include mediaquery(s) {
      @include spacing(l 35vw);
    }

    @include mediaquery(m) {
      border-width: $width-icon-border * 2;
    }

    @include mediaquery(l) {
      @include spacing(r -3, l 45vw);
    }

    @include mediaquery(xl) {
      @include spacing(t -6, l 55vw);
      @include spacing-inner(l 3);
    }

    @include mediaquery(xxl) {
      @include spacing(l 55vw);
      @include spacing-inner(l 4);
    }

    @include mediaquery(xxxl) {
      @include spacing(l 26);
    }

    a {
      color: $color-second;
    }
  }

  &--plain {
    @include spacing(b 3);

    @include mediaquery(m) {
      @include spacing(t -4);
    }

    @include mediaquery(l) {
      @include spacing(t -16, r 35vw);
    }

    @include mediaquery(xl) {
      @include spacing(t -14, r 30vw);
    }

    @include mediaquery(xxxl) {
      width: $base-unit * 24;
    }
  }
}
