.blog,
.blog--detail {
  @include spacing-inner(h 0, t 0);

  @include mediaquery(m) {
    @include spacing-inner(l 1.6);
  }

  article {
    @include relative;
  }

  time {
    @include spacing(b .5);
    @include font-size(h6);
    display: block;
    color: $color-main;
  }
}

.blog {
  article {
    @include spacing-inner(h 1, t 3);

    @include mediaquery(m) {
      @include spacing-inner(h 2);
    }

    @include mediaquery(l) {
      @include spacing-inner(a 2, t 3);
      height: $base-quadruple * 3.4;
    }

    @include mediaquery(xxxl) {
      @include spacing-inner(t 4);
      height: $base-quadruple * 3.75;
    }

    &.blog-item--highlight:nth-of-type(odd) {
      background: $color-second-light;

      @include mediaquery(l) {
        height: $base-quadruple * 5;
      }

      .blog-item--with-img {
        @include spacing(l 0);
        width: 75%;
      }

      img {
        @include spacing(b 1);

        @include mediaquery(m) {
          @include spacing(b 2);
        }

        @include mediaquery(l) {
          left: $base-quadruple * 2.5;
          top: $base-quadruple * 2.25;
          max-width: $base-quadruple * 5;
        }
      }
    }

    &:nth-of-type(odd) {
      background: $color-second;

      @include mediaquery(l) {
        img {
          @include absolute(l 2, t 2);
        }

        .blog-item--with-img {
          @include spacing(l 40vw);

          @include mediaquery(xxxl) {
            @include spacing(l 22);
          }
        }
      }

      p {
        color: $color-inverted;
      }
    }

    &:nth-of-type(even) {
      border: $width-icon-border $color-main solid;

      @include mediaquery(m) {
        border-width: $width-icon-border * 2;
      }

      @include mediaquery(l) {
        img {
          @include absolute(r 2, t 2);
        }

        .blog-item--with-img {
          @include spacing(r 40vw);

          @include mediaquery(xxxl) {
            @include spacing(r 22);
          }
        }
      }

      time {
        color: $color-second;
      }

      .blog--readon {
        color: $color-second;
      }
    }

    header {
      @include spacing(b .5);
    }

    img {
      @include relative;
      @include z-index(belly);
      @include spacing(b -1, t 1);

      @include mediaquery(l) {
        max-width: $base-quadruple * 3;
        max-height: $base-quadruple * 3;
      }

      @include mediaquery(xl) {
        max-width: $base-quadruple * 3.6;
      }

      @include mediaquery(xxl) {
        max-width: $base-quadruple * 4.3;
      }

      @include mediaquery(xxxl) {
        max-width: $base-quadruple * 5;
      }
    }

    p {
      @include spacing(b .5);
    }
  }

  &--readon {
    text-decoration: underline;
  }

  &-item--without-img {
    @include spacing(b 2);
  }

  &--detail {
    header {
      @include spacing-inner(h 1, t 5, b 8);
      background: $color-second-light;
      color: $color-inverted;

      @include mediaquery(m) {
        @include spacing-inner(h 2);
      }

      @include mediaquery(l) {
        @include spacing-inner(h 4);
      }

      blockquote {
        color: $color-main;
      }
    }

    .link--back {
      @include mediaquery(m) {
        left: $base-double * 1.75;
      }

      @include mediaquery(l) {
        left: $base-quadruple * 1.4;
      }
    }
  }

  &-item__content {
    @include spacing-inner(h 1);

    @include mediaquery(m) {
      @include spacing-inner(h 2);
    }

    @include mediaquery(l) {
      @include spacing-inner(h 4);
    }

    a {
      color: $color-second;
    }
  }

  &-item__img--featured {
    @include spacing(t -8);
  }

  &-item__gallery--wrapper {
    @include relative;

    @include mediaquery(l) {
      @include spacing(l -2, r -1);
    }
  }

  &-item__gallery {
    @include relative;

    @include mediaquery(l) {
      @include spacing(h .75);
      overflow: hidden;

      .blog-item__gallery--slide & {
        height: 15rem;
      }
    }
  }

  &__gallery {
    @extend %clearfix;
    @extend %animate;

    @include mediaquery(l) {
      .blog-item__gallery--slide & {
        @include absolute;
        width: 3000px;
      }

      figure {
        @include spacing(h .25);
        display: table;
        float: left;
        width: 30%;
        height: 11rem;
        overflow: hidden;
        text-align: center;
      }

      figcaption {
        @include font-size(h6);
        display: table-caption;
        caption-side: bottom;
        text-align: left;
        max-height: $base-double * 1.1;
      }

      img {
        max-height: $height-gallery-img;
      }
    }

    img {
      @include spacing(b .5);
    }
  }

  &__gallery-nav {
    $offsettop: px-to-rem($height-gallery-img / 2) - parse-unit($height-gallery-arrow / 2);
    @include absolute(t $offsettop);
    display: none;

    &--next {
      right: 0;
    }

    .blog-item__gallery--slide & {
      @include mediaquery(l) {
        display: block;
      }
    }
  }
}

.nav-blog {
  @include spacing(t 2);
  text-align: center;

  a {
    @include spacing-inner(h .2);
    @include spacing(h .1);
    color: $color-default;
    text-align: center;
    display: inline-block;

    &.page--active {
      background: $color-main;
      color: $color-second;
    }
  }
}
