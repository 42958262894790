// a helper for using the same media-queries in js and css
.display-type:before {
  @extend %visuallyhidden;
  content: 'default';

  @each $breakpoint, $breakpoint-value in $breakpoints {
    @include mediaquery(#{$breakpoint}) {
      content: '#{$breakpoint}';
    }
  }
}
