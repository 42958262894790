// global footer styles
// ======================================================================
footer {
  @include relative;
  @include z-index(head);
  @include spacing(t 1);
  @include spacing-inner(h .75, v .5);
  background: $color-second;
  text-align: right;

  a {
    @include letter-spacing(m);
    color: $color-inverted;
    font-weight: 300;
  }
}

.nav-footer {
  ul {
    @include spacing(b 0);
  }

  li {
    @include font-size(h5);
    @include spacing-inner(h .5);
    display: none;

    @include mediaquery(l) {
      display: inline-block;
    }

    &:last-child {
      @include spacing-inner(l 1.5);
      display: inline-block;
    }
  }
}
