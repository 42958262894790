// easy margins and paddings with custom mixins
// ======================================================================

// creates margins, by default (without any parameters) only margin-bottom
// with $base-unit -- default rhythm, or for every side/value-pair with
// their short variants:
//
// usage examples:
//
// @include spacing; ==> margin-bottom: $base-unit;
// @include spacing(2) ==> warning!, this is not gonna work
// @include spacing(b 2) ==> margin-bottom: $base-unit * 2;
// @include spacing(a 2) ==> margin: $base-unit * 2;
// @include spacing(a 0.5, b 1) ==> margin: $base-unit * 0.5; margin-bottom: $base-unit;
// @include spacing(v 0.5, h 1) ==> margin-top: $base-unit * 0.5; margin-bottom: $base-unit * 0.5; margin-left: $base-unit; magin-right: $base-unit;
// @include spacing(r 1, l 12%) ==> margin-right: base-unit; margin-left: 12%;
// @include spacing(r 0, t auto) ==> margin-right: 0; $margin-top: auto;
@mixin spacing($spacing-args...) {

  // default case for spacing -- margin bottom
  @if length($spacing-args) == 0 {
    margin-bottom: $base-unit;

  } @else {
    @each $position-key, $position-value in $spacing-args {

      @if not $position-value {
        @warn 'You should provide a list of side/value pairs e.g. "b 2" for this mixin to work';

      } @else if $position-key == 'a' {
        margin: parse-unit($position-value);

      } @else if $position-key == 'v' {
        margin-top: parse-unit($position-value);
        margin-bottom: parse-unit($position-value);

      } @else if $position-key == 'h' {
        margin-left: parse-unit($position-value);
        margin-right: parse-unit($position-value);

      } @else {
        $side: position-map($position-key);
        $value: parse-unit($position-value);

        margin-#{$side}: $value;
      }
    }
  }

}


// this inner-spacing mixin for padding behaves slightly different
// when provided with no parameter, padding is applied to all four
// sides of the element, using padding-shorthand
@mixin spacing-inner($spacing-args...) {

  // default case for spacing -- margin bottom
  @if length($spacing-args) == 0 {
    padding: $base-unit;

  } @else {
    @each $position-key, $position-value in $spacing-args {

      @if not $position-value {
        @warn 'You should provide a list of side/value pairs e.g. "b 2" for this mixin to work';

      } @else if $position-key == 'a' {
        padding: parse-unit($position-value);

      } @else if $position-key == 'v' {
        padding-top: parse-unit($position-value);
        padding-bottom: parse-unit($position-value);

      } @else if $position-key == 'h' {
        padding-left: parse-unit($position-value);
        padding-right: parse-unit($position-value);

      } @else {
        $side: position-map($position-key);
        $value: parse-unit($position-value);

        padding-#{$side}: $value;
      }
    }
  }

}
