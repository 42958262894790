// default vertical rhythm / margin-bottom spacing
// ======================================================================

h1,
h2,
ul,
ol,
dl,
blockquote,
p,
address,
hr,
table,
fieldset,
figure,
img,
mark,
pre,
%rhythm {
  @include spacing(b 2);

  @include mediaquery(xl) {
    @include spacing(b 3);
  }
}

h3,
h4,
h5,
h6 {
  @include spacing(b .75);

  @include mediaquery(xl) {
    @include spacing(b 1.5);
  }
}
