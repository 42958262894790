// custom @font-face includes are defined here - use mixin
// ======================================================================

// include font-face like this:
// @include font-face('Font', '../fonts/filewithoutextension', 700, italic);
// @include font-face('Other Font', '../fonts/otherfont', 400, normal);
// and have a look at /src/js/modules/fonts.js and include those there
// too, for fast loadtimes with fontfaceobserver etc.
@include font-face('Laila', '../fonts/Laila-Bold', 700, normal);
@include font-face('Laila', '../fonts/Laila-Semibold', 600, normal);
@include font-face('WorkSans', '../fonts/WorkSans-Light', 300, normal);
@include font-face('WorkSans', '../fonts/WorkSans-SemiBold', 600, normal);
