.centaur {
  &__toggle-formatting {
    @extend %visuallyhidden;

    &:checked + label {
      .icon--checkbox--checked {
        display: inline-block;
      }
    }

    &:checked ~ .centaur__content {
      min-width: 42rem;
    }

    &--label {
      @include relative;
      @include spacing-inner(l .75);

      .icon {
        @include absolute;
      }
    }
  }

  &__content {
    @include spacing(t 2);

    @include mediaquery(xl) {
      @include spacing(t 4);
      @include spacing-inner(l 4);
    }
  }

  &__preamble {
    @include mediaquery(l) {
      @include spacing-inner(t 1);
    }

    @include mediaquery(xl) {
      display: none;
    }
  }

  &__toggle-formatting--label {
    @include mediaquery(xl) {
      display: none;
    }
  }

}
